import { MetaData } from "booking_app/types/meta-data";

export const metaData: MetaData  = {
  "home": {
    title: "호텔 예약 | 아멕스 MR 포털",
  },
  // Hotels
  "hotel-home": {
    title: "호텔 예약 | 아멕스 MR 포털",
  },
  "search-result": {
    title: "호텔 예약 결과조회 | 아멕스 MR 포털",
  },
  "hotel-detail": {
    title: "호텔 예약 세부내역 | 아멕스 MR 포털",
  },
  "checkout": {
    title: "호텔 예약 결제 | 아멕스 MR 포털",
  },
  "confirmation": {
    title: "호텔 예약 완료 | 아멕스 MR 포털",
  },
  "manage-booking": {
    title: "호텔 예약 관리 | 아멕스 MR 포털",
  },
};
