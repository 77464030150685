require('../hermes-setup');

global.loadWlDeps = function () {
  require('angular-animate');
  require('../../assets/material/angular-material.min');
  require('angular-aria');
}

global.wlAngularDeps = [
  "ngAnimate",
  "ngMaterial",
  "ngAria",
];

global.loadAppDeps = function () {
  loadHermesDeps();
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/hyundai/locales');
  require('../../assets/javascripts/whitelabel/hyundai/controllers/hyundai-controller');
  require('../../assets/javascripts/whitelabel/hyundai/components');
  require('../../assets/javascripts/whitelabel/hyundai/services');
}

require('../application');
