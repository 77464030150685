import { HeaderUrlLink } from "booking_app/constants/header-url-link";
export class HeaderMenuList {
  static $inject = [
    "$rootScope",
    "$window",
  ];

  public headerList: HeaderUrlLink[];

  constructor(
    private $rootScope: any,
    private $window: any,
  ) {
    this.setupHeaderList();
  }

  setupHeaderList(): void {
    this.headerList = [
      {
        title: "My Account",
        active: false,
        requireLoggedIn: true,
        contentType: "link",
        links: [
          {
            title: "wl.hyundai.membership_rewards_activity",
            siteUrl: `${this.$rootScope.rdUrl}/points_activity`,
          },
          {
            title: "wl.hyundai.manage_my_bookings",
            siteUrl: `${this.$window.location.origin}/account/booking`,
          },
        ],
      },
      {
        title: "Language",
        active: false,
        requireLoggedIn: false,
        contentType: "locale",
        links: [],
      },
    ];
  }
}
angular.module("BookingApp").service("HeaderMenuList", HeaderMenuList);
