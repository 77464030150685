import { Modal } from "booking_app/types/modal";
import { modalSettings } from "booking_app/constants/modal-settings";

modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalIconCDNPath = "/hyundai/icons/red-warning.svg";
modalSettings[Modal.HOTEL_ROOM_UNAVAILABLE].content.modalHeader = "wl.hyundai.room_not_available";
modalSettings[Modal.CANCEL_BOOKING_SUCCESS].content.modalIconCDNPath = "/hyundai/icons/icn-confirmed.svg";
modalSettings[Modal.CANCEL_BOOKING].content.modalHeader = "wl.hyundai.confirm_cancellation";
modalSettings[Modal.CANCEL_BOOKING].content.resolveButtonText = "wl.yes_cancel";
modalSettings[Modal.CANCEL_BOOKING].content.rejectButtonText = "wl.no_keep_booking";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalIconCDNPath = "/hyundai/icons/icn-confirmed.svg";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalHeader = "wl.booking_cancelled_header";
modalSettings[Modal.RESEND_CONFIRMATION_SUCCESS].content.modalBody = "wl.hyundai.confirmation_email_sent";
modalSettings[Modal.USER_INELIGIBILITY_MODAL].content.modalIconCDNPath = "/hyundai/icons/failed.svg";
