import { AppSettings } from "booking_app/values/app-settings";
import { LoginModalWindowClassOption, MomentDateType, SortType, Tenant } from "booking_app/types";
import { metaData } from "../constants/meta-data";
import { HermesService } from "booking_app/services/hermes/hermes.service";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { SearchSortingService } from "booking_app/services/search-sorting.service";
import { DocumentService } from "booking_app/services/document.service";
import "../constants/modal-settings";

declare var angular: any;
declare var moment: any;

angular.module("BookingApp").config([
  "$ariaProvider",
  ($ariaProvider) => {
    $ariaProvider.config({
      bindRoleForClick: false,
      tabindex: false,
    });
  },
]);

export class HyundaiController {

  static $inject = [
    "$rootScope",
    "AppSettings",
    "HermesService",
    "GlobalStateService",
    "SearchSortingService",
    "UtilsService",
    "DocumentService",
  ];

  constructor(
    public $rootScope: any,
    public appSettings: AppSettings,
    public hermesService: HermesService,
    public globalStateService: GlobalStateService,
    private searchSortingService: SearchSortingService,
    private utilsService: any,
    private documentService: DocumentService,
  ) {
    this.appSettings.tenant = Tenant.HYUNDAI;
    this.appSettings.e2eWhitelabel = true;
    this.$rootScope.selectedCurrency = this.$rootScope.currenciesService.findByCode("KRW");
    this.$rootScope.globalState.showTotalNights = true;
    this.appSettings.showCancelPolicyOnRedeem = true;
    this.appSettings.isTravelBreakdownFlexDirectionDefault = false;
    this.appSettings.ccValidationUseCardErrorModal = true;
    this.appSettings.dateFormat = MomentDateType.ll;
    this.appSettings.hasMultiplePaymentMethods = true;
    this.appSettings.multiplePointsPartner = true;
    // Login & Logout URLs
    this.appSettings.logoutUrl = "/whitelabel/hyundai/logout";

    this.$rootScope.globalState.loginEndpoint = "whitelabel/hyundai/login";
    this.appSettings.mapboxHotelMarkerColor = "#006fcf";
    this.$rootScope.globalState.requireLoginOnCheckout = true;
    this.appSettings.loginModalConfig.loginModalWindowClass = LoginModalWindowClassOption.LOGIN_MODAL_VALIDATION_CLASS;
    // Slider settings (filter slider and points cash slider)
    this.appSettings.showCurrencyInFilterSliders = false;
    this.appSettings.pointsCashSliderSettings.pointSliderDefaultValue = 5;
    this.appSettings.pointsCashSliderSettings.incrementPointsSliderCounter = false;
    this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel = true;
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "90% Cash";
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = "0% Cash";
    this.$rootScope.enabledLocales = this.utilsService.filterArrayAttr(
      this.$rootScope.enabledLocales,
      ["ko", "en"],
      "code",
    );
    this.$rootScope.globalState.serviceEmail = "support@myamexrewards.com";

    this.appSettings.sortSettings.canUseFullPointsOnRedemption = true;
    this.appSettings.sortSettings.showPointsSortInFullPoints = false;

    // Modal settings
    this.appSettings.showGulfAlert = false;
    this.appSettings.ccValidationUseCardErrorModal = true;

    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "hyundai/icons/icon-filter.svg",
      sortIconSource: "hyundai/icons/icon-sort.svg",
      mapIconSource: "hyundai/icons/icon-map.svg",
    };
    this.appSettings.storeCreditCard = true;

    this.searchSortingService.initializePermittedSortingOptions([
      SortType.BEST_RATED,
      SortType.PAY_POINTS_CASH_LOHI,
      SortType.PAY_POINTS_CASH_HILO,
      SortType.RATING_LOHI,
      SortType.RATING_HILO,
    ]);
    this.appSettings.roomGuestParameters = {
      labelOnlyRoomCounter: true,
      hasRoomGuestCloser: true,
      labelOnlySummaryText: true,
    };
    this.appSettings.pointsCashSliderSettings.ceilingLabelPrefix = "";
    this.appSettings.payWithPoints.roundToNearest = 1;
    this.appSettings.hideCheckoutFormPostalCode = true;
    this.appSettings.checkoutSettings.insufficientPointsModalCheck = true;
    this.appSettings.checkoutSettings.addNewCardText = "wl.credit_card";
    this.appSettings.userIneligibilityCheck = true;
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.appSettings.materialImplementation = true;
    // Hermes setup
    this.appSettings.hermesConfig.timeout = 1800;
    this.appSettings.hotelBookingDetailToggles.showGuestsSummary = true;
    this.appSettings.hotelBookingDetailToggles.showEmptyRoomRemarks = true;
    this.appSettings.hotelBookingDetailToggles.showRoomsLabel = true;

    if (this.$rootScope.isUserLoggedIn()) {
      this.hermesService.initHermes();
    }

    this.documentService.initDocumentTitleAndMeta(metaData);

    this.$rootScope.$on("$routeChangeSuccess", () => {
      if (this.$rootScope.isUserLoggedIn()) {
        this.$rootScope.userDetails.selectedPointsBalance =
          this.$rootScope.userDetails.user.points_balances[0].points_balance;
        this.$rootScope.userDetails.selectedPointsAccountName =
          this.$rootScope.userDetails.user.points_balances[0].name;
      }
    });
  }

}

angular.module("BookingApp").controller("HyundaiController", HyundaiController);
