import { PaymentSettings } from "booking_app/types/payment-settings";

export class PaymentStylingSettings {
  public paymentSettings: PaymentSettings;
  constructor(
  ) {
    this.setupPaymentStyling();
  }

  setupPaymentStyling(): void {
    // Default base style
    this.paymentSettings = {
      stripe: {
        base: {
          "color": "#333333",
          "fontSize": "16px",
          "fontWeight": "400",
          "fontSmoothing": "antialiased",
          "::placeholder": {
            color: "#97999B",
            fontSmoothing: "antialiased",
            fontSize: "16px",
          },
        },
        invalid: {
          color: "#333333",
          fontSize: "16px",
        },
      },
    };
  }
}
angular.module("BookingApp").service("PaymentStylingSettings", PaymentStylingSettings);
